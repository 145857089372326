export const SECTION_TYPES = [
    {
        id: 1,
        text: 'Articles',
    },
    {
        id: 2,
        text: 'Quizs',
    },
    {
        id: 3,
        text: 'Pages',
    }
]
export const PAGE_TYPE = [
    {
        id: 1,
        text: 'web view',
    },
    {
        id: 2,
        text: 'Internal View',
    },
    {
        id: 3,
        text: 'Redirece Browser',
    }
]

export const CAT_VIEW_TYPES = [
    {
        id: 1,
        text: 'Home',
    },
    {
        id: 2,
        text: 'Sidebar',
    },
    {
        id: 3,
        text: 'Home & Sidebar',
    }
]
export const ARTICLE = 1;
export const QUIZ = 2;
export const PAGE = 3;
